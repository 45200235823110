'use strict';

// Declare app level module which depends on views, and components
angular.module('myApp', [
  'ngRoute',
//  'ngStorage',
  'ngAnimate',
//  'ngTouch',
//  'ui.bootstrap',
  'myApp.buy',
  'myApp.address'
])
.constant('config', {
    //apiUrl: 'http://beercolormeter.shinovent.fi/api/'
    apiUrl: 'http://beercolormeter.local.host/api/'
})
.config(['$routeProvider', function ($routeProvider) {
    $routeProvider.otherwise({redirectTo: '/buy'});
  }])

.controller('appController', function($rootScope, $scope, $window){
  $rootScope.showComments = false;
  
  function resizeNow() {
    $rootScope.windowHeight = $("html").height();
    $rootScope.windowWidth = $("html").width();
    $rootScope.containerWidth = $("#intro .container").width() - 30;
    if($("html").height() > $("html").width()) {
      $rootScope.portrait = 1;
      $("div.dia.full-page").css('min-height', '');
      $("div#flyer-video iframe").width($rootScope.containerWidth-30);
      $("div#flyer-video iframe").height(($rootScope.containerWidth-30)*0.56);
      console.log('portrait');
    } else if ($("html").width() >= 992) {
      $rootScope.portrait = 0;
      $("div.dia.full-page").css('min-height', $rootScope.windowHeight);
      $("div.dia.full-page .floating-content").height($rootScope.windowHeight);
      $("div#flyer-video iframe").width($rootScope.containerWidth*0.75);
      $("div#flyer-video iframe").height(($rootScope.containerWidth*0.75)*0.56);
      console.log('video:'+$rootScope.containerWidth*0.75+'x'+($rootScope.containerWidth*0.75)*0.56);
      console.log('landscape fullpage');
    } else {
      $("div.dia.full-page").css('min-height', '');
      $("div.dia.full-page .floating-content").height('');
      $("div#flyer-video iframe").width($rootScope.containerWidth);
      $("div#flyer-video iframe").height($rootScope.containerWidth*0.56);
      console.log('landscape normal');
    }
  }
  
  function isElementInViewport (el) {

      //special bonus for those using jQuery
      if (typeof jQuery === "function" && el instanceof jQuery) {
          el = el[0];
      }

      var rect = el.getBoundingClientRect();

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
      );
  }
  
  var handler =  function() {
      var el = document.getElementById("comments");
      var visible = isElementInViewport(el);
      if (visible) {
        // LOAD COMMENTS FROM DISQUSS
        $(window).off('DOMContentLoaded load scroll');
      }
  };

  angular.element($window).bind('resize', function(){
    resizeNow();
  });
  
  //init
  resizeNow();
  $('.parallaxie').parallaxie({
	"speed": 1,
	"size": "auto"
  });
  
  //$(window).on('DOMContentLoaded load resize scroll', handler);
  $("html").removeClass('no-js').addClass('js');
})

.service('customerdata', function ($http, config) {
  
})

.service('api', function ($http, config) {
  var errorCallback = function (data, status, headers, config) {
    console.log('ERROR' + data.error.message);
  };

  var successCallback = function (data, status, headers, config) {
    return data;
  };

  return {
    apiInit: function () {
      return $http.get(apiUrl+'init').then(
         function (response) {
           return response;
      }, function (error) {
        errorCallback(error);
      });
    },
    apiGetStockQuantity: function () {
      return $http.get(apiUrl+'stockQty').then(
         function (response) {
           return response;
      }, function (error) {
        errorCallback(error);
      });
    },
    apiAddToCart: function () {
      return $http.get(apiUrl+'addToCart').then(
         function (response) {
           return response;
      }, function (error) {
        errorCallback(error);
      });
    }
  };
})
.directive('lazyLoad', ['$window', '$q', function ($window, $q) {
    function load_script() {
        var s = document.createElement('script'); // use global document since Angular's $document is weak
        s.src = 'https://maps.googleapis.com/maps/api/js?sensor=false&callback=initialize';
        document.body.appendChild(s);
    }
    function lazyLoadApi(key) {
        var deferred = $q.defer();
        $window.initialize = function () {
            deferred.resolve();
        };
        // thanks to Emil Stenström: http://friendlybit.com/js/lazy-loading-asyncronous-javascript/
        if ($window.attachEvent) {  
            $window.attachEvent('onload', load_script); 
        } else {
            $window.addEventListener('load', load_script, false);
        }
        return deferred.promise;
    }
    return {
        restrict: 'E',
        link: function (scope, element, attrs) { // function content is optional
        // in this example, it shows how and when the promises are resolved
            if ($window.google && $window.google.maps) {
                console.log('gmaps already loaded');
            } else {
                lazyLoadApi().then(function () {
                    console.log('promise resolved');
                    if ($window.google && $window.google.maps) {
                        console.log('gmaps loaded');
                    } else {
                        console.log('gmaps not loaded');
                    }
                }, function () {
                    console.log('promise rejected');
                });
            }
        }
    };
}]);