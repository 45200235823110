'use strict';

angular.module('myApp.address', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/address', {
    templateUrl: 'address/address.html',
    controller: 'addressCtrl as vm'
  });
}])

.controller('addressCtrl', [function() {
        var vm = this;
        vm.countries = {
          'Austria': 'Austria',
          'Italy': 'Italy',
          'Belgium': 'Belgium',
          'Latvia': 'Latvia',
          'Bulgaria': 'Bulgaria',
          'Lithuania': 'Lithuania',
          'Croatia': 'Croatia',
          'Luxembourg': 'Luxembourg',
          'Cyprus': 'Cyprus',
          'Malta': 'Malta',
          'Czech Republic': 'Czech Republic',
          'Netherlands': 'Netherlands',
          'Denmark': 'Denmark',
          'Poland': 'Poland',
          'Estonia': 'Estonia',
          'Portugal': 'Portugal',
          'Finland': 'Finland',
          'Romania': 'Romania',
          'France': 'France',
          'Slovakia': 'Slovakia',
          'Germany': 'Germany',
          'Slovenia': 'Slovenia',
          'Greece': 'Greece',
          'Spain': 'Spain',
          'Hungary': 'Hungary',
          'Sweden': 'Sweden',
          'Ireland': 'Ireland',
          'United Kingdom': 'United Kingdom'
        };
}]);
