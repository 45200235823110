'use strict';

angular.module('myApp.terms', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/terms', {
    templateUrl: 'terms/terms.html',
    controller: 'termsCtrl as vm'
  });
}])

.controller('termsCtrl', function(customerdata) {
        var vm = this;
        vm.customerdata = customerdata;
});