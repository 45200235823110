'use strict';

angular.module('myApp.buy', ['ngRoute'])
  .config(['$routeProvider', function ($routeProvider) {
      $routeProvider.when('/buy', {
        templateUrl: 'buy/buy.html',
        controller: 'buyCtrl as vm'
      });
    }])

  .controller('buyCtrl', function ($location, api) {
    var vm = this;
    vm.stockQtyAvailable = 0;
    vm.buy = function () {
      $location.path('terms');
    };
  });